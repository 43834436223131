class Pagination {
  constructor({
    page,
    total,
    perPage,
    nextGoogleStartBookIndex,
    isAllBooksFromGoogleFetched,
  }) {
    this.total = total;
    this.perPage = perPage;
    this.currentPage = page;
    this.lastPage = Math.ceil(total / perPage);
    this.nextGoogleStartBookIndex = nextGoogleStartBookIndex;
    this.isAllBooksFromGoogleFetched = isAllBooksFromGoogleFetched;
  }

  hasNextPage() {
    return (
      !this.isAllBooksFromGoogleFetched && this.currentPage < this.lastPage
    );
  }

  getNextPage() {
    return this.currentPage + 1;
  }
}

export default Pagination;

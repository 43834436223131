<template>
  <section class="catalogued">
    <div class="container-fluid">
      <img
        class="catalogued__banner"
        src="@/assets/img/catalogued_until_now.jpg"
        alt="Opened book"
      />
      <div class="catalogued__title">
        <div class="catalogued__container">
          <h2>
            <span v-text="`${count} `"></span>
            <span v-text="$t('books')"></span>
            <span
              class="catalogued__subtitle"
              v-text="$t('catalogued until now')"
            ></span>
          </h2>
        </div>
      </div>
      <div class="catalogued__card">
        <div class="catalogued__container">
          <p class="catalogued__text" v-text="$t('Description')"></p>
          <div v-if="!loggedIn" class="d-flex">
            <router-link
              class="btn btn--primary btn--join"
              :to="$localePath('signup')"
              v-text="$t('Join Us Now')"
            ></router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<i18n src="./translations.json"></i18n>

<script>
import books from '@/api/books';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      count: 0,
    };
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
  },

  watch: {
    '$i18n.locale': function () {
      this.countBooks();
    },
  },

  created() {
    this.countBooks();
  },

  methods: {
    async countBooks() {
      this.count = await books.count();
    },
  },
};
</script>

<style lang="scss">
@import './catalogued.scss';
</style>

<template>
  <section class="recently-catalogued">
    <div class="container-fluid">
      <h2 class="text-center" v-text="$t('Recently catalogued')"></h2>
      <div class="recently-catalogued__slider">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(book, index) in books"
              :key="book.id"
              class="swiper-slide"
            >
              <book-card :key="book.id" :book="book" :index="index" />
            </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
      <div class="t-center">
        <router-link
          :to="{ name: 'books.recent' }"
          class="btn btn--secondary btn--view-all"
          v-text="$t('View All')"
        ></router-link>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation } from 'swiper';
import BookCard from '@/components/bookCard/BookCard';
import 'swiper/swiper-bundle.css';
import books from '../../../api/books';

export default {
  components: {
    BookCard,
  },

  data() {
    return {
      books: [],
      swiper: undefined,
      justSwipedAuto: false,
    };
  },

  watch: {
    '$i18n.locale': function () {
      this.fetchBooks();
    },
  },

  created() {},

  mounted() {
    this.fetchBooks();
  },

  methods: {
    async fetchBooks() {
      const { results } = await books.recent({
        language: this.$i18n.locale,
      });

      const exandriaBooks = results;

      this.books = [
        exandriaBooks[exandriaBooks.length - 1],
        ...exandriaBooks.slice(2, -1),
        exandriaBooks[0],
        exandriaBooks[1],
      ];
      this.$nextTick(() => {
        Swiper.use([Navigation]);

        this.swiper = new Swiper('.swiper-container', {
          slidesPerView: 1,
          spaceBetween: 140,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1200: {
              slidesPerView: 2,
              spaceBetween: 70,
            },
          },
        });
        this.swiper.on('slideChange', (e) => {
          if (this.justSwipedAuto) return;
          if (
            e.previousIndex < e.realIndex &&
            e.realIndex === exandriaBooks.length + 1
          ) {
            this.justSwipedAuto = true;
            this.swiper.slideTo(0, 0);
            setTimeout(() => {
              this.swiper.slideTo(1);
              this.justSwipedAuto = false;
            });
          } else if (e.previousIndex > e.realIndex && e.realIndex === 0) {
            this.justSwipedAuto = true;
            this.swiper.slideTo(exandriaBooks.length + 1, 0);
            setTimeout(() => {
              this.swiper.slideTo(exandriaBooks.length);
              this.justSwipedAuto = false;
            });
          }
        });
        this.swiper.slideTo(1);
      });
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss">
@import './recentlyCatalogued.scss';

.swiper-slide-duplicate .book__description {
  height: 99px;
  overflow: hidden;
}
</style>

import api from '@/http/client';
import ExandriaBook from '@/models/ExandriaBook';
import GoogleBook from '@/models/GoogleBook';
import Pagination from '@/helpers/Pagination';

export default {
  async get(url, body, params = {}) {
    const {
      booksFromDb,
      booksFromGoogle,
      page,
      perPage,
      nextGoogleStartBookIndex,
      isAllBooksFromGoogleFetched,
      total,
    } = await api.$post(url, body, { params });

    const googleBooks = booksFromGoogle
      ? booksFromGoogle.flat().map((book) => {
          const { id, saleInfo, volumeInfo } = book;
          const data = { id, type: 'google', ...saleInfo, ...volumeInfo };
          return new GoogleBook(data);
        })
      : [];

    const exandriaBooks = booksFromDb.length
      ? booksFromDb
          .map((book) => {
            return new ExandriaBook({ type: 'db', ...book });
          })
          .sort((a, b) => (a.title < b.title ? -1 : 0))
      : [];

    return {
      results: [...exandriaBooks, ...googleBooks],
      pagination: new Pagination({
        page,
        nextGoogleStartBookIndex,
        isAllBooksFromGoogleFetched,
        perPage,
        total,
      }),
    };
  },

  searchAdvance(body, params = {}) {
    return this.get('/books/search', body, params);
  },

  searchBasic(body, params = {}) {
    return this.get('/books/basic-search', body, params);
  },

  count(params = {}) {
    return api.$get('/books/count', params);
  },

  async recent(params) {
    const {
      books,
      page,
      perPage,
      nextGoogleStartBookIndex,
      isAllBooksFromGoogleFetched,
      total,
    } = await api.$get('/books/recent', params);

    const exandriaBooks = books
      .map((book) => {
        return new ExandriaBook({ type: 'db', ...book });
      })
      .sort((a, b) => (a.title < b.title ? -1 : 0));

    return {
      results: exandriaBooks,
      pagination: new Pagination({
        page,
        nextGoogleStartBookIndex,
        isAllBooksFromGoogleFetched,
        perPage,
        total,
      }),
    };
  },
};

<template>
  <div class="book-card">
    <div class="book-card__header">
      <router-link
        class="book-card__img"
        :to="
          $localePath(
            'books.show',
            { isbn: book.getISBN() },
            book.isExandria() ? undefined : { googleId: book.id }
          )
        "
      >
        <img :src="book.getImage()" :alt="$t('Book image')" />
      </router-link>

      <div class="book-card__top">
        <router-link
          :to="
            $localePath(
              'books.show',
              { isbn: book.getISBN() },
              book.isExandria() ? undefined : { googleId: book.id }
            )
          "
        >
          <h4 class="book-card__title">
            <read-more :height="44" :text="book.getTitle()" />
          </h4>
        </router-link>
        <p>
          <em>{{ book.getAuthors().toString() }}</em>
        </p>
      </div>

      <div v-if="book.isExandria()" class="book-card__label">
        <img src="@/assets/img/books_icon.svg" :alt="$t('Books')" />
        <span v-if="book.isExandria()">eXandria</span>
      </div>
    </div>
    <div class="book-card__data">
      <read-more
        v-if="book.getDescription()"
        v-slot="{ toggle, isClamped, isClampable }"
        class="book-card__description"
        :text="book.getDescription()"
      >
        <div>
          <button v-if="isClampable" class="btn--readmore" @click="toggle">
            {{ isClamped ? $t('Read more') : $t('Read less') }}
          </button>
        </div>
      </read-more>
    </div>
    <div class="book-card__text">
      <p class="mb-1">
        <strong>{{ $t('About the book') }}</strong>
      </p>
      <ul class="list-unstyled">
        <li v-if="book.hasGenres()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('Genre') }}:</div>
          <div class="book-attribute__value">
            {{ book.getGenres().toString() }}
          </div>
        </li>
        <li v-if="book.hasTime()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('Time') }}:</div>
          <div class="book-attribute__value">{{ book.getTime() }}</div>
        </li>
        <li v-if="book.hasPlaces()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('Place') }}:</div>
          <div class="book-attribute__value">
            {{ book.getPlaceNames($i18n.locale).toString() }}
          </div>
        </li>
        <li v-if="book.hasTags()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('Tags') }}:</div>
          <div class="book-attribute__value">
            {{ book.getTags().toString() }}
          </div>
        </li>

        <li v-if="book.hasTrueEvents()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('True events') }}:</div>
          <div class="book-attribute__value">
            {{ book.getTrueEvents().toString() }}
          </div>
        </li>
        <li v-if="book.hasRealCharacters()" class="book-attribute">
          <div class="book-attribute__key">{{ $t('Real characters') }}:</div>
          <div class="book-attribute__value">
            {{ book.getRealCharacters().toString() }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ReadMore from '@/components/readMore/ReadMore';

export default {
  name: 'BookCard',

  components: {
    ReadMore,
  },

  props: {
    book: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState({
      fontsLoaded: 'fontsLoaded',
    }),
    averageRate() {
      if (
        !this.book.averageRate ||
        !Object.values(this.book.averageRate)?.length
      ) {
        return null;
      }
      const values = Object.values(this.book.averageRate);

      return Array.isArray(values)
        ? (
            values.reduce((acc, curr) => acc + +curr, 0) / values.length
          ).toFixed(2)
        : values;
    },
  },
};
</script>

<i18n src="./bookCard.json"></i18n>

<style lang="scss">
@import './bookCard.scss';
</style>

<template>
  <section class="banner banner--xl banner--top hero_wrapper mb-4">
    <div class="container t-center">
      <div class="banner__description">
        <h2
          class="hero_title"
          v-html="$t('Save the books you love from oblivion.')"
        ></h2>
        <h2
          class="hero_title"
          v-html="$t('Make them searchable, today and forever.')"
        ></h2>
      </div>
    </div>
    <div class="search-basic">
      <form @submit.prevent="search">
        <label for="basic-query" v-text="$t('Title, author, ISBN')"></label>
        <input
          id="basic-query"
          v-model="queryString"
          type="text"
          class="form-control__underline"
          :placeholder="$t('e.g. Ken Follett')"
          aria-label="Search"
        />
        <button
          type="submit"
          class="btn btn-sm btn--primary search_button"
          :class="{ 'btn--disabled': !hasQueryString }"
          v-text="$t('Search')"
        ></button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Hero',

  data() {
    return {
      queryString: '',
    };
  },

  computed: {
    hasQueryString() {
      return this.queryString.trim();
    },
  },

  methods: {
    search() {
      if (!this.hasQueryString) {
        return;
      }

      const route = this.$localePath('books', null, {
        queryString: this.queryString,
      });

      this.$router.push(route);
    },
  },
};
</script>

<i18n src="./translations.json"></i18n>

<style lang="scss" scoped>
@import '@/assets/styles/_banners.scss';
@import './hero.scss';
@import './search-basic.scss';
</style>

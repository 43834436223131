<template>
  <div class="home">
    <hero />
    <how-it-works />
    <books-count />
    <recently-catalogued />
    <search-advance />
  </div>
</template>

<script>
import BooksCount from './booksCount/BooksCount';
import Hero from './hero/Hero';
import HowItWorks from './howItWorks/HowItWorks';
import RecentlyCatalogued from './recentlyCatalogued/RecentlyCatalogued';
import SearchAdvance from './searchAdvance/SearchAdvance';

export default {
  name: 'Home',
  components: {
    BooksCount,
    Hero,
    HowItWorks,
    SearchAdvance,
    RecentlyCatalogued,
  },
};
</script>

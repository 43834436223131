import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=d3170c7a&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"
import style0 from "./Hero.vue?vue&type=style&index=0&id=d3170c7a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3170c7a",
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fviews%2Fhome%2Fhero%2FHero.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports
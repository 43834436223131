import { render, staticRenderFns } from "./HowItWorks.vue?vue&type=template&id=10fe9710&"
var script = {}
import style0 from "./HowItWorks.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Fviews%2Fhome%2FhowItWorks%2FHowItWorks.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports